// getUserAgent.js
'use strict';
import UAParser from 'ua-parser-js';

import {
  breakpoint,
  mobileAspectRatioWidth,
  mobileAspectRatioHeight,
} from '../style/variables.js';

import { getIsInBrowserMainThread } from '../resource/getJsEnvironment.js';

/** @type {UAParser.UAParser} */
let parser = undefined;
let userAgent = undefined;
let platform = undefined;
let appBundleName = undefined;
let browserVersion = undefined;
let shouldUsePusherWorker = undefined;
let isOnIOS = undefined;
let isOnAndroid = undefined;
let isOnApple = undefined;
let isOnMobile = undefined;
let isSafari = undefined;
let isIosSafari = undefined;
let isIosChrome = undefined;
let isIosSafari10 = undefined;
let isIosSafari12 = undefined;
let isOnDesktopSafari = undefined;
let isRecordAllowed = undefined;
let isFbInAppBrowser = undefined;
let isLineInAppBrowser = undefined;
let isWechatInAppBrowser = undefined;
let isInAppBrowser = undefined;
let isXUserAgent = undefined;
let isOnAndroidWebview = undefined;
let isOnIosWebview = undefined;
let isInPwa = undefined;
let isIphoneXSeries = undefined;
let isIphoneXSeriesPWA = undefined;
let isHigherAndroidChrome80 = undefined;
let isChrome = undefined;
let isFirefox = undefined;
let isOpera = undefined;
let isEdge = undefined;
let isEdgeChromium = undefined;
let isMicrosoftEdge = undefined;
let isOnWindows = undefined;
let isOnMacOS = undefined;

let appBundleIds = undefined;

const SERVER_AGENT_NAME = 'Server';

const exceptionMobileDeviceName = /HUAWEI|Meitu/gi; // TODO: remote config

const isClient = getIsInBrowserMainThread();

export const getUserAgent = () => {
  if (!parser) {
    parser = new UAParser();
  }
  if (!userAgent) {
    userAgent = parser.getResult();
  }
  return userAgent;
};

export const getPlatform = () => {
  if (undefined === platform) {
    if (getIsOnMobile()) {
      if (getIsOnAndroid()) {
        platform = 'Android';
      }
      if (getIsOnIOS()) {
        platform = 'iOS';
      }
    } else {
      platform = 'desktop';
    }
  }
  return platform;
};

export const getOSName = () => {
  const ua = getUserAgent();
  return ua.os.name;
};

export const getBundleNameInWebview = () => {
  if (!isClient) return false;
  if (getIsXUserAgent() && (getIsOnAndroid() || getIsOnApple())) {
    if (!appBundleName) {
      let ua = '';
      if (getIsOnAndroid()) ua = window?.WebApp?.userAgent;
      if (getIsOnApple())
        ua = window?.webkit?.messageHandlers?.WebApp?.userAgent;

      // fallback, if still no ua in WebApp
      if (!ua) ua = getUserAgent()?.ua;

      const matchedBundleName = appBundleIds?.find(id => ua?.includes(id));
      appBundleName = matchedBundleName;
    }
    return appBundleName;
  }
};

export const getGenericOSName = () => {
  const oreiginName = isClient && window?.navigator?.userAgent.toLowerCase();
  if (!oreiginName) return 'None';

  if (oreiginName.includes('iphone') || oreiginName.includes('ipad'))
    return 'ios';
  if (oreiginName.includes('android')) return 'android';
  if (oreiginName.includes('macintosh') || oreiginName.includes('mac os x'))
    return 'mac';
  if (oreiginName.includes('windows')) return 'windows';
  if (oreiginName.includes('linux')) return 'linux';
  return 'None';
};

export const getDeviceType = () => {
  const ua = getUserAgent();
  return ua.device.type;
};

export const getIsOnWindows = () => {
  if (undefined === isOnWindows) {
    isOnWindows = ['windows'].includes(getUserAgent().os.name?.toLowerCase());
  }

  return isOnWindows;
};

export const getIsOnMacOS = () => {
  if (undefined === isOnMacOS) {
    isOnMacOS = ['Mac OS'].includes(getUserAgent().os.name);
  }

  return isOnMacOS;
};

export const getIsOnApple = () => {
  if (undefined === isOnApple) {
    const vendor = ['iOS', 'Mac OS', 'Apple'];
    isOnApple =
      vendor.includes(getUserAgent().os.name) ||
      vendor.includes(getUserAgent().device.vendor);
  }
  return isOnApple;
};

export const getIsOnAndroid = () => {
  if (undefined === isOnAndroid) {
    isOnAndroid = ['Android'].includes(getUserAgent().os.name);
  }
  return isOnAndroid;
};

export const getIsOnIOS = () => {
  if (undefined === isOnIOS) {
    isOnIOS = ['iOS'].includes(getUserAgent().os.name);
  }
  return isOnIOS;
};

/** @returns {boolean} */
export const getIsOnMobile = () => {
  if (undefined === isOnMobile) {
    isOnMobile =
      getUserAgent().device.type ||
      getUserAgent().ua.match(exceptionMobileDeviceName);
  }
  return !!isOnMobile;
};

export const setAppBundleIds = ({ ids }) => {
  if (!isClient) return;
  if (ids) appBundleIds = ids;

  const { ua } = getUserAgent();
  // Update isXUserAgent if bundle ids changed
  isXUserAgent = isXUserAgent =
    typeof WebApp !== 'undefined' || // Android
    !!window?.webkit?.messageHandlers?.WebApp || // iOS
    !!appBundleIds?.find(id => ua.includes(id)); // fallback in old mobile version
};

export const getIsXUserAgent = () => {
  if (!isClient) return false;
  if (undefined === isXUserAgent) {
    const { ua } = getUserAgent();
    isXUserAgent =
      typeof WebApp !== 'undefined' || // Android
      !!window?.webkit?.messageHandlers?.WebApp || // iOS
      // Fallback, check if mobile modified user agent in old version
      !!appBundleIds?.find(id => ua.includes(id));
  }
  return isXUserAgent;
};

export const getIsOnAndroidWebview = () => {
  if (undefined === isOnAndroidWebview) {
    isOnAndroidWebview = getIsXUserAgent() && getIsOnAndroid();
  }
  return isOnAndroidWebview;
};

export const getIsOnIOSWebview = () => {
  if (undefined === isOnIosWebview) {
    isOnIosWebview = getIsXUserAgent() && getIsOnApple();
  }
  return isOnIosWebview;
};

/** @returns {{major: Number, minor: Number, patch: Number}} */
export const getIosVersion = () => {
  if (undefined === browserVersion) {
    const [major, minor, patch] = getUserAgent()
      .os?.version?.split('.')
      .map(s => +s) || ['0', '0', '0'];
    browserVersion = { major, minor, patch };
  }
  return browserVersion;
};

export const getIsIos10 = () => {
  if (undefined === isIosSafari10) {
    if (!getIsOnIOS()) {
      isIosSafari10 = false;
    } else {
      const { major } = getIosVersion();
      if (10 !== major) {
        isIosSafari10 = false;
      } else {
        isIosSafari10 = true;
      }
    }
  }
  return isIosSafari10;
};

export const getIsIos12 = () => {
  if (undefined === isIosSafari12) {
    if (!getIsOnIOS()) {
      isIosSafari12 = false;
    } else {
      const { major } = getIosVersion();
      if (12 !== major) {
        isIosSafari12 = false;
      } else {
        isIosSafari12 = true;
      }
    }
  }
  return isIosSafari12;
};

export const getShouldUsePusherWorker = () => {
  if (undefined === shouldUsePusherWorker) {
    if (!getIsOnIOS()) {
      shouldUsePusherWorker = true;
    } else {
      const { major, minor } = getIosVersion();
      if (12 === major && 1 < minor) {
        shouldUsePusherWorker = false;
      } else if (13 === major && 0 === minor) {
        shouldUsePusherWorker = false;
      } else {
        shouldUsePusherWorker = true;
      }
    }
  }
  return shouldUsePusherWorker;
};

export const getIsIosSafari = () => {
  if (!getIsOnIOS()) {
    isIosSafari = false;
  } else {
    const { browser } = getUserAgent();
    const { name } = browser;
    if (name === 'Mobile Safari') {
      isIosSafari = true;
    } else {
      isIosSafari = false;
    }
  }
  return isIosSafari;
};

export const getIsIosChrome = () => {
  if (!getIsOnIOS()) {
    isIosChrome = false;
  } else {
    if (/chrome/gi.test(getUserAgent()?.browser?.name)) {
      isIosChrome = true;
    } else {
      isIosChrome = false;
    }
  }
  return isIosChrome;
};

export const getIsOnDesktopSafari = () => {
  if (undefined === isOnDesktopSafari) {
    isOnDesktopSafari = 'Safari' === getUserAgent().browser.name;
  }
  return isOnDesktopSafari;
};

export const getIsSafari = () => {
  getIsOnDesktopSafari();
  getIsIosSafari();

  isSafari = isOnDesktopSafari || isIosSafari;
  return isSafari;
};

export const getIsFbInAppBrowser = () => {
  if (undefined === isFbInAppBrowser) {
    if (isClient) {
      isFbInAppBrowser = userAgent.browser.name === 'Facebook';
    }
  }
  return isFbInAppBrowser;
};

export const getIsLineInAppBrowser = () => {
  if (undefined === isLineInAppBrowser) {
    if (isClient) {
      isLineInAppBrowser = userAgent.browser.name === 'Line';
    }
  }
  return isLineInAppBrowser;
};

export const getIsWechatInAppBrowser = () => {
  if (undefined === isWechatInAppBrowser) {
    if (isClient) {
      const reg = /wechat/i;
      isWechatInAppBrowser = reg.test(userAgent.browser.name);
    }
  }
  return isWechatInAppBrowser;
};

export const getIsInAppBrowser = () => {
  if (undefined === isInAppBrowser) {
    if (isClient) {
      isInAppBrowser =
        getIsWechatInAppBrowser() ||
        getIsLineInAppBrowser() ||
        getIsFbInAppBrowser();
    }
  }
  return isInAppBrowser;
};

export const getIsRecordAllowed = () => {
  if (undefined !== isRecordAllowed) {
    return isRecordAllowed;
  }
  if (false === getIsOnMobile()) {
    isRecordAllowed = false;
    return isRecordAllowed;
  }
  if (true === getIsOnIOS()) {
    isRecordAllowed = true;
    return isRecordAllowed;
  }
  const {
    browser: { name },
  } = getUserAgent();
  if (/(samsung|edge|UCBrowser|chrome)/gi.test(name)) {
    isRecordAllowed = true;
    return isRecordAllowed;
  }
  isRecordAllowed = false;
  return isRecordAllowed;
};

export const getIsInPwa = () => {
  if (!isClient) return false;
  if (undefined === isInPwa) {
    isInPwa =
      isClient &&
      (('matchMedia' in window &&
        window.matchMedia('(display-mode: standalone)').matches) ||
        ('standalone' in window.navigator && window.navigator.standalone));
  }
  return isInPwa;
};

export const userAgentString = isClient
  ? navigator.userAgent
  : SERVER_AGENT_NAME;

export const getIsIphoneXSeries = () => {
  if (!isClient) return false;
  const checkDeviseSize = (devicePixelRatio, width, height) => {
    return (
      window.devicePixelRatio === devicePixelRatio &&
      window.screen.width === width &&
      window.screen.height === height
    );
  };

  if (undefined === isIphoneXSeries) {
    const isIphone = getUserAgent()?.device?.model?.includes('iPhone');
    if (!isIphone || !window.devicePixelRatio) return (isIphoneXSeries = false);

    // Ref: https://www.ios-resolution.com/
    const sizeList = [
      // iPhone X, 11 Pro, iPhone 13 mini
      [3, 375, 812],
      // iPhone Xs Max, 11 Pro Max
      [3, 414, 896],
      // iPhone 11 Pro, iPhone XR
      [2, 414, 896],
      // iPhone 12 Pro, iPhone 13 Pro, iPhone 13, iPhone 14
      [3, 390, 844],
      // iPhone 12 Pro Max, iPhone 13 Pro Max, iPhone 14 Plus
      [3, 428, 926],
      // iPhone 14 Pro, iPhone 15, iPhone 15 Pro, iPhone 16
      [3, 393, 852],
      // iPhone 14 Pro Max, iPhone 15 Plus, iPhone 15 Pro Max, iPhone 16 Plus
      [3, 430, 932],
      // iPhone 16 Pro
      [3, 402, 874],
      // iPhone 16 Pro Max
      [3, 440, 956],
    ];

    isIphoneXSeries = !!sizeList.find(size => checkDeviseSize(...size));
  }

  return isIphoneXSeries;
};

export const getIsIphoneXSeriesPWA = () => {
  if (undefined === isIphoneXSeriesPWA) {
    const isInPwa = getIsInPwa();
    const isIphoneXSeries = getIsIphoneXSeries();
    isIphoneXSeriesPWA = isInPwa && isIphoneXSeries;
  }
  return isIphoneXSeriesPWA;
};

export const getBrowserVersion = () => {
  const [major, minor] = getUserAgent()
    .browser?.version?.split('.')
    .map(s => s) || ['0', '0'];
  return { major, minor };
};

export const getIsHigherAndroidChrome80 = () => {
  if (undefined === isHigherAndroidChrome80) {
    const { major } = getBrowserVersion();
    if (major >= 80 && getIsOnMobile() && getIsOnAndroid()) {
      isHigherAndroidChrome80 = true;
    } else {
      isHigherAndroidChrome80 = false;
    }
  }
  return isHigherAndroidChrome80;
};

export const getIsChrome = () => {
  if (isChrome === undefined) {
    const { browser } = getUserAgent();
    if (/chrome/gi.test(browser.name)) {
      isChrome = true;
    } else {
      isChrome = false;
    }
  }
  return isChrome;
};

export const getIsFirefox = () => {
  if (isFirefox === undefined) {
    const { browser } = getUserAgent();
    if (/firefox/gi.test(browser.name)) {
      isFirefox = true;
    } else {
      isFirefox = false;
    }
  }
  return isFirefox;
};

export const getIsOpera = () => {
  if (isOpera === undefined) {
    const { browser } = getUserAgent();
    if (/opera/gi.test(browser.name)) {
      isOpera = true;
    } else {
      isOpera = false;
    }
  }
  return isOpera;
};

export const getIsEdge = () => {
  if (isEdge === undefined) {
    const { browser } = getUserAgent();
    if (/edge/gi.test(browser.name)) {
      isEdge = true;
    } else {
      isEdge = false;
    }
  }
  return isEdge;
};

// Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36 Edg/113.0.1774.42
export const getIsEdgeChromium = () => {
  if (isEdgeChromium === undefined) {
    const { engine, browser } = getUserAgent();
    if (/^Blink$/i.test(engine.name) && /^Edge$/i.test(browser.name)) {
      isEdgeChromium = true;
    } else {
      isEdgeChromium = false;
    }
  }
  return isEdgeChromium;
};

// Mozilla/5.0 (Windows NT 10.0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/42.0.2311.135 Safari/537.36 Edge/12.10136
export const getIsMicrosoftEdge = () => {
  if (isMicrosoftEdge === undefined) {
    const { engine, browser } = getUserAgent();
    if (/^EdgeHTML$/i.test(engine.name) && /^Edge$/i.test(browser.name)) {
      isMicrosoftEdge = true;
    } else {
      isMicrosoftEdge = false;
    }
  }
  return isMicrosoftEdge;
};

export const getIsPortrait = () => {
  if (!isClient) {
    return false;
  }

  return window.matchMedia('(orientation: portrait)').matches;
};

/**
 * Use device orientation and aspect ratio to check is mobile or not.
 * @returns {Boolean} return true if device match mobile condition.
 */
export const getIsOnMobileBySize = () => {
  if (!isClient) {
    return false;
  }

  const screenWidth = window?.innerWidth;
  const screenHeight = window?.innerHeight;
  const isPortrait = getIsPortrait();
  const aspectRatio = screenWidth / screenHeight;

  return isPortrait
    ? aspectRatio <= mobileAspectRatioWidth / mobileAspectRatioHeight
    : aspectRatio >= mobileAspectRatioHeight / mobileAspectRatioWidth &&
        screenWidth <= breakpoint.tablet;
};

export const getBrowserName = () => {
  const { browser } = getUserAgent();
  return browser.name;
};

export default getUserAgent;
