/**
 * Main function
 * 1. registerServiceWorker: register service worker
 * 2. initPusher: init pusher
 * 3. handleServiceWorkerClaim: handle new service worker or when user hard reload
 * 4. cleanup: clean up the queue when there is no new service worker
 * 5. ensureServiceWorker: after pusher config setup completed, wait for 10 secs, if isAllCompleted is false, fallback to main thread
 *
 * Handle cases:
 * 1. No service worker
 * case 1: _registerServicerWorker_fallback
 *         _initPusher_fallback
 *
 * 2. Has service worker
 * case 1: registerServiceWorker
 *         initPusher
 *
 * case 2: initPusher
 *         registerServiceWorker
 *
 * case 3: (hard reload)
 *         registerServiceWorker
 *         initPusher (only config)
 *         handleServiceWorkerClaim -> _initPusher
 *
 * case 4: (hard reload)
 *         initPusher (only config)
 *         registerServiceWorker
 *         handleServiceWorkerClaim -> _initPusher
 *
 * case 5: (new service worker)
 *         registerServiceWorker
 *         initPusher
 *         handleServiceWorkerClaim -> _reInitPusher
 *
 * case 6: (new service worker)
 *         initPusher
 *         registerServiceWorker
 *         handleServiceWorkerClaim -> _reInitPusher
 *
 * case 7: (slow network, hard reload or new service worker)
 *         registerServiceWorker
 *         handleServiceWorkerClaim -> do nothing
 *         initPusher
 */
import isMatch from 'lodash/isMatch';

import { getShouldUsePusherWorker } from '../resource/getUserAgent.js';
import { getIsInServiceWorker } from '../resource/getJsEnvironment.js';
import setServiceWorker from '../action/setServiceWorker.js';
import {
  INIT_PUSHER,
  USER_ONLINE_STATUS,
  GET_AB_TEST_TOKEN,
  SET_AUTH_HEADERS,
  SEND_PLAYER_STATUS_TO_CHANNEL,
  SUBSCRIBE_STREAM_CHANNEL,
  UNSUBSCRIBE_STREAM_CHANNEL,
  SUBSCRIBE_STREAM_VIEWER_CHANNEL,
  UNSUBSCRIBE_STREAM_VIEWER_CHANNEL,
} from './AppMessageTypes.js';
import unRegisterServiceWorker from '../resource/unRegisterServiceWorker.js';
import { serviceWorkerLog as serviceWorkerDebug } from '../resource/debug.js';
import VisibilityManager from '../resource/VisibilityManager.js';
import { messengerMessageIdKey } from '../resource/ServiceWorkerMessenger.js';

const isOnServiceWorker = getIsInServiceWorker();
const isProd = 'production' === process.env.NODE_ENV;
const isServer = typeof window === 'undefined';

let pendingMessageQueue = [];
// create a new queue to store message before sw claimed, after sw claimed, re-send these messages.
let beforeSWClaimedMessageQueue = [];
let pendingSWMessageQueue = [];
const subscribedCriticalChannelQueue = [];
const subscribedNonCriticalChannelQueue = [];

let dispatch = null;
let pusherConfig = null;

let visibilityManager = null;

export const hasServiceWorker =
  typeof navigator !== 'undefined' && 'serviceWorker' in navigator;

const logMap = {
  default: serviceWorkerDebug,
  registerServiceWorker: serviceWorkerDebug.extend('registerServiceWorker'),
  _registerServiceWorker: serviceWorkerDebug.extend('_registerServiceWorker'),
  _registerServiceWorker_fallback: serviceWorkerDebug.extend(
    '_registerServiceWorker_fallback'
  ),
  handleServiceWorkerClaim: serviceWorkerDebug.extend(
    'handleServiceWorkerClaim'
  ),
  initPusher: serviceWorkerDebug.extend('initPusher'),
  _initPusher: serviceWorkerDebug.extend('_initPusher'),
  _initPusher_fallback: serviceWorkerDebug.extend('_initPusher_fallback'),
  flushPendingMessageQueue: serviceWorkerDebug.extend(
    'flushPendingMessageQueue'
  ),
  flushBeforeSWClaimedMessageQueue: serviceWorkerDebug.extend(
    'flushBeforeSWClaimedMessageQueue'
  ),
  sendMessageToSW: serviceWorkerDebug.extend('sendMessageToSW'),
  ensureServiceWorker: serviceWorkerDebug.extend('ensureServiceWorker'),
  serviceWorkerFallbackToMainThread: serviceWorkerDebug.extend(
    'serviceWorkerFallbackToMainThread'
  ),
  cleanup: serviceWorkerDebug.extend('cleanup'),
  resetFcmToken: serviceWorkerDebug.extend('resetFcmToken'),

  messenger: serviceWorkerDebug.extend('messenger'),
};

const serviceWorkerLog = ({ scope, messages, data }) => {
  const logger = logMap[scope] || logMap.default;
  if (data) {
    return logger(...messages, data);
  }
  return logger(...messages);
};

const state = {
  isSWRegistered: false,
  isSWRegisteredFailed: false,
  isSWClaimed: false,
  isInitPusherCompleted: false,
  isPusherInSW: false,
  isPusherConfigSetupCompleted: false,

  get isAllCompleted() {
    return this.isSWRegistered && this.isInitPusherCompleted;
  },

  get canFlushMessageQueue() {
    return this.isAllCompleted;
  },

  get canSendMessageToSW() {
    return (
      hasServiceWorker && this.isAllCompleted && !this.isSWRegisteredFailed
    );
  },

  reset() {
    this.isSWRegistered = false;
    this.isSWRegisteredFailed = false;
    this.isSWClaimed = false;
    this.isInitPusherCompleted = false;
    this.isPusherInSW = false;
    this.isPusherConfigSetupCompleted = false;
  },
};

export const registerServiceWorker = async () => {
  if (hasServiceWorker) {
    // refer to: https://stackoverflow.com/questions/35628243/how-to-prevent-hard-reloads-from-bypassing-the-service-worker
    // unregister service worker when there is no controller
    if (!navigator.serviceWorker.controller) {
      const registration = await navigator.serviceWorker.getRegistration();
      // sync controller again after await
      const controller = navigator.serviceWorker.controller;

      let refreshBy = null;
      if (!controller && !registration?.active) {
        // sometimes we still got 'new' on hard-reload,
        // but this case could be auto recovered anyways
        refreshBy = 'new';
      } else if (!controller && registration?.active) {
        refreshBy = 'hard-reload';
      } else if (controller && !registration?.active) {
        refreshBy = 'unknown';
      } else {
        refreshBy = 'reload';
      }

      if (refreshBy === 'hard-reload') {
        serviceWorkerLog({
          scope: 'registerServiceWorker',
          messages: ['unregister start'],
        });
        await unRegisterServiceWorker();
        serviceWorkerLog({
          scope: 'registerServiceWorker',
          messages: ['unregister done'],
        });
      }
    }

    serviceWorkerLog({
      scope: 'registerServiceWorker',
      messages: ['register service worker'],
    });
    requestIdleCallback(_registerServiceWorker);
  } else {
    serviceWorkerLog({
      scope: 'registerServiceWorker',
      messages: ['register service worker fallback'],
    });
    _registerServiceWorker_fallback();
  }
};

export const _registerServiceWorker = async () => {
  navigator.serviceWorker
    .register(window.serviceWorkerPath, { scope: '/' })
    .then(register => {
      register.addEventListener(
        'updatefound',
        handleServiceWorkerClaim(register)
      );

      return isProd
        ? true
        : serviceWorkerLog({
            scope: '_registerServiceWorker',
            messages: ['Service worker setup.'],
            data: register,
          });
    })
    .catch(error => {
      serviceWorkerLog({
        scope: '_registerServiceWorker',
        messages: ['register service worker failed'],
        data: error,
      });
      state.isSWRegisteredFailed = true;

      return isProd
        ? null
        : serviceWorkerLog({
            scope: '_registerServiceWorker',
            messages: ['register service worker failed'],
            data: error,
          });
    })
    .finally(() => {
      serviceWorkerLog({
        scope: '_registerServiceWorker',
        messages: ['register service worker completed'],
      });
      state.isSWRegistered = true;
      flushPendingMessageQueue();
      cleanup();
    });

  try {
    serviceWorkerLog({
      scope: '_registerServiceWorker',
      messages: ['add message event listener'],
    });
    // Start listening to Service Worker events
    navigator.serviceWorker.addEventListener('message', event => {
      if (event?.data?.[messengerMessageIdKey]) {
        // events for new pattern
        return;
      }
      handleMessageFromSW(event.data);
    });
  } catch (error) {
    const { getSentry } = await import('../resource/sentry.js');
    const { withScope, captureException } = await getSentry();
    if (withScope && captureException) {
      withScope(scope => {
        scope.setFingerprint(['enter-app-select-pusher-path', error.name]);
        captureException(error);
      });
    }
  }
};

const _registerServiceWorker_fallback = () => {
  serviceWorkerLog({
    scope: '_registerServiceWorker_fallback',
    messages: ['register service worker fallback completed'],
  });
  state.isSWRegistered = true;
  flushPendingMessageQueue();
};

const handleServiceWorkerClaim = register => () => {
  serviceWorkerLog({
    scope: 'handleServiceWorkerClaim',
    messages: ['updatefound'],
  });
  const newWorker = register.installing || register.waiting || register.active;

  newWorker?.addEventListener('statechange', () => {
    // newWorker.state;
    // "installing" - the install event has fired, but not yet complete
    // "installed"  - install complete
    // "activating" - the activate event has fired, but not yet complete
    // "activated"  - fully active
    // "redundant"  - discarded. Either failed install, or it's been
    //                replaced by a newer version
    if (newWorker.state === 'activated') {
      serviceWorkerLog({
        scope: 'handleServiceWorkerClaim',
        messages: ['statechange'],
        data: newWorker.state,
      });
      state.isSWClaimed = true;
      if (state.isInitPusherCompleted) {
        serviceWorkerLog({
          scope: 'handleServiceWorkerClaim',
          messages: ['re-init pusher'],
        });
        _reInitPusher();
      } else if (state.isPusherConfigSetupCompleted) {
        serviceWorkerLog({
          scope: 'handleServiceWorkerClaim',
          messages: ['init pusher'],
        });
        _initPusher();
      }

      serviceWorkerLog({
        scope: 'handleServiceWorkerClaim',
        messages: ['might reset fcm token'],
      });
      resetFcmToken();
    }
  });
};

const isPusherInSWPromise = () =>
  !isOnServiceWorker &&
  new Promise(resolve => {
    resolve(
      getShouldUsePusherWorker() &&
        typeof navigator !== 'undefined' &&
        navigator.serviceWorker?.controller &&
        // .ready is a promise
        navigator.serviceWorker.ready
    );
  }).then(Boolean);

/**
 * Initialize MessageRouter, this should be called before sending any messages
 * @param {object} params
 * @param {import('redux').Dispatch} params.dispatch Dispatch function from redux store
 * @param {object} params.headers
 */
export const initPusher = async ({
  dispatch: _dispatch,
  pusherConfig: _pusherConfig,
}) => {
  if (dispatch) {
    serviceWorkerLog({
      scope: 'initPusher',
      messages: ['initPusher has already being called.'],
    });
    return;
  }
  dispatch = _dispatch;
  pusherConfig = _pusherConfig;
  state.isPusherConfigSetupCompleted = true;
  serviceWorkerLog({
    scope: 'initPusher',
    messages: ['pusher config setup completed'],
  });

  flushPendingSWMessageQueue();

  if (hasServiceWorker) {
    state.isPusherInSW =
      pusherConfig.shouldUsePusherOnServiceWorkerEnv &&
      (await isPusherInSWPromise());

    if (state.isPusherInSW) {
      serviceWorkerLog({
        scope: 'initPusher',
        messages: ['pusher is already in service worker'],
      });
      _initPusher();
    }

    if (state.isSWClaimed && !state.isInitPusherCompleted) {
      serviceWorkerLog({
        scope: 'initPusher',
        messages: ['after service worker claim'],
      });
      _initPusher();
    }
    if (state.isSWClaimed) {
      serviceWorkerLog({
        scope: 'initPusher',
        messages: ['might reset fcm token'],
      });
      resetFcmToken();
    }

    // ensure service worker
    ensureServiceWorker();
  } else {
    serviceWorkerLog({
      scope: 'initPusher',
      messages: ['no server worker, init pusher fallback'],
    });
    _initPusher_fallback();
  }
};

const _initPusher = async () => {
  sendMessageToSW({
    type: INIT_PUSHER,
    payload: { pusherConfig },
  });
  serviceWorkerLog({
    scope: '_initPusher',
    messages: ['init pusher completed'],
  });
  state.isInitPusherCompleted = true;
  flushPendingMessageQueue();
  cleanup();
  dispatch(setServiceWorker({ isUsing: true }));
};

const _initPusher_fallback = () => {
  sendMessageToSW({
    type: INIT_PUSHER,
    payload: { pusherConfig },
  });
  serviceWorkerLog({
    scope: '_initPusher_fallback',
    messages: ['init pusher fallback completed'],
  });
  state.isInitPusherCompleted = true;
  flushPendingMessageQueue();
  dispatch(setServiceWorker({ isUsing: false }));
};

const _reInitPusher = () => {
  flushBeforeSWClaimedMessageQueue();
};

/**
 * @param {object} event
 * @param {string} event.type
 * @param {object} event.payload
 */
export const sendMessageToSW = async event => {
  if (isServer) return;

  const send = () => {
    if (state.canSendMessageToSW) {
      serviceWorkerLog({
        scope: 'sendMessageToSW',
        messages: ['send message to service worker'],
        data: { event },
      });
      _sendMessageToSW(event);
    } else {
      serviceWorkerLog({
        scope: 'sendMessageToSW',
        messages: ['send message fallback'],
        data: { event },
      });
      _sendMessageToSW_fallback(event);
    }
  };

  if (state.isAllCompleted) send();
  else pendingMessageQueue.push(send);

  if (!state.isSWClaimed) beforeSWClaimedMessageQueue.push(send);

  addToSubscribedChannelQueue({ event, send });
};

const _sendMessageToSW = async event => {
  navigator.serviceWorker?.controller?.postMessage(event);
};

const _sendMessageToSW_fallback = async event => {
  // invoke handleMessageFromApp directly
  const handleMessageFromApp = (await import('./handleMessageFromApp.js'))
    .default;
  handleMessageFromApp(event);
};

const flushPendingMessageQueue = () => {
  if (state.canFlushMessageQueue && pendingMessageQueue.length) {
    serviceWorkerLog({
      scope: 'flushPendingMessageQueue',
      messages: ['start to flush pending message queue'],
      data: pendingMessageQueue.length,
    });
    pendingMessageQueue.forEach(cb => cb());
    pendingMessageQueue = [];
  }
};

// to sync the status with the new service worker
// flush the message queue which store the messages before service worker is claimed
const flushBeforeSWClaimedMessageQueue = () => {
  if (state.canFlushMessageQueue && beforeSWClaimedMessageQueue.length) {
    serviceWorkerLog({
      scope: 'flushBeforeSWClaimedMessageQueue',
      messages: ['start flush message queue (messages before sw claimed)'],
      data: beforeSWClaimedMessageQueue.length,
    });
    beforeSWClaimedMessageQueue.forEach(cb => cb());
    beforeSWClaimedMessageQueue = [];
  }
};

export const serviceWorkerFallbackToMainThread = async ({
  dispatch: _dispatch,
  pusherConfig: _pusherConfig,
} = {}) => {
  if (_dispatch) dispatch = _dispatch;
  if (_pusherConfig) pusherConfig = _pusherConfig;

  serviceWorkerLog({
    scope: 'serviceWorkerFallbackToMainThread',
    messages: ['fallback to main thread'],
  });

  const { getSentry } = await import('../resource/sentry.js');
  const { withScope, captureException } = await getSentry();
  if (withScope && captureException) {
    withScope(scope => {
      scope.setFingerprint(['serviceWorker', 'ensureServiceWorker']);
      scope.setTag('record', 'serviceWorker');
      scope.setExtra('state', state);
      captureException(new Error('service worker fallback to main thread'));
    });
  }

  unRegisterServiceWorker();
  state.isSWRegisteredFailed = true;
  _registerServiceWorker_fallback();
  _initPusher_fallback();
  resubscribeChannels();
  dispatch(setServiceWorker({ isUsing: false }));
};

const cleanup = () => {
  if (state.isAllCompleted) {
    setTimeout(() => {
      // there seems no new service worker
      if (!state.isSWClaimed) {
        serviceWorkerLog({
          scope: 'cleanup',
          messages: ['assume no new service worker'],
        });
        state.isSWClaimed = true; // set to true, so sendMessageToSW won't push message to beforeSWClaimedMessageQueue
        beforeSWClaimedMessageQueue = []; // clear the beforeSWClaimedMessageQueue because there is no new service worker
      }
    }, 10000); // TODO: remote config
  }
};

const ensureServiceWorker = () => {
  if (isServer) return;

  const ensure = () =>
    setTimeout(() => {
      if (!state.isAllCompleted) {
        serviceWorkerLog({
          scope: 'ensureServiceWorker',
          messages: ['some error happen, fallback to main thread'],
          data: { ...state },
        });
        serviceWorkerFallbackToMainThread();
      }
    }, 10000); // TODO: remote config

  if (!document.hidden) {
    ensure();
  } else if (!visibilityManager) {
    visibilityManager = new VisibilityManager({
      visibleListener: () => {
        ensure();
        visibilityManager.unbindListeners();
      },
    });
  }
};

/**
 * @param {object} event
 * @param {string} event.type
 * @param {object} event.payload
 */
let _handleMessageFromSW = null;
export const handleMessageFromSW = async event => {
  if (isServer) return;

  if (!dispatch) pendingSWMessageQueue.push(event);
  else {
    if (!_handleMessageFromSW) {
      const { handleMessageFromSW: handleMessageFromSWModule } = await import(
        './handleMessageFromSW.js'
      );
      _handleMessageFromSW = handleMessageFromSWModule(dispatch);
    }

    _handleMessageFromSW(event);
  }
};

const flushPendingSWMessageQueue = async () => {
  if (dispatch && pendingSWMessageQueue.length) {
    serviceWorkerLog({
      scope: 'flushPendingSWMessageQueue',
      messages: ['start flush message from service worker'],
      data: pendingSWMessageQueue.length,
    });

    if (!_handleMessageFromSW) {
      const { handleMessageFromSW: handleMessageFromSWModule } = await import(
        './handleMessageFromSW.js'
      );
      _handleMessageFromSW = handleMessageFromSWModule(dispatch);
    }

    pendingSWMessageQueue.forEach(event => _handleMessageFromSW(event));
    pendingSWMessageQueue = [];
  }
};

/**
 * @param {object} event
 * @param {string} event.type
 * @param {object} event.payload
 */
export const sendMessageToApp_fallback = async event => {
  if (event?.[messengerMessageIdKey]) {
    // events for new pattern
    return;
  }
  // invoke handleMessageFromSW directly
  handleMessageFromSW(event);
};

// TODO: remote config (start)
const CRITICAL_CHANNEL_EVENTS = [
  GET_AB_TEST_TOKEN,
  SET_AUTH_HEADERS,
  SEND_PLAYER_STATUS_TO_CHANNEL,
  SUBSCRIBE_STREAM_CHANNEL,
  UNSUBSCRIBE_STREAM_CHANNEL,
  SUBSCRIBE_STREAM_VIEWER_CHANNEL,
  UNSUBSCRIBE_STREAM_VIEWER_CHANNEL,
];
const NON_CRITICAL_CHANNEL_EVENTS = [USER_ONLINE_STATUS]; // and other events start with SUBSCRIBE
const MAX_NON_CRITICAL_EVENTS_NUMBER = 200;
// TODO: remote config (end)

const addToSubscribedChannelQueue = ({ event: newEvent, send }) => {
  let queue;
  let isNonCritical = false;
  const newEventType = newEvent.type?.replace('UNSUBSCRIBE', 'SUBSCRIBE');

  if (CRITICAL_CHANNEL_EVENTS.includes(newEventType))
    queue = subscribedCriticalChannelQueue;
  else if (
    NON_CRITICAL_CHANNEL_EVENTS.includes(newEventType) ||
    newEventType?.startsWith('SUBSCRIBE')
  ) {
    queue = subscribedNonCriticalChannelQueue;
    isNonCritical = true;
  }

  if (queue) {
    const duplicateIndices = queue
      .map(({ event }, index) => ({ event, index }))
      .filter(
        ({ event }) =>
          event.type === newEventType &&
          isMatch(event.payload, newEvent.payload)
      )
      .map(({ index }) => index);

    const isUnsubscribedEvent = newEvent.type?.startsWith('UNSUBSCRIBE');

    // remove the relative subscribed events of the unsubscribed event
    if (duplicateIndices.length && isUnsubscribedEvent)
      duplicateIndices.forEach(index => queue.splice(index, 1));
    // no duplicated, and is not unsubscribed event, push to queue
    else if (!duplicateIndices.length && !isUnsubscribedEvent)
      queue.push({ event: newEvent, send });

    // restrict the size of non critical events queue
    if (isNonCritical && queue.length > MAX_NON_CRITICAL_EVENTS_NUMBER)
      queue.shift();
  }
};

export const resubscribeChannels = () => {
  subscribedCriticalChannelQueue.forEach(({ send }) => send());
  subscribedNonCriticalChannelQueue.forEach(({ send }) => send());
};

const resetFcmToken = async () => {
  if (!dispatch) return;
  serviceWorkerLog({
    scope: 'resetFcmToken',
    messages: ['reset fcm token'],
  });

  const { default: setFcmToken } = await import('../action/setFcmToken.js');

  dispatch(setFcmToken());
};
